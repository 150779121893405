import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import React, { Fragment, useState } from 'react';

import { TextField } from 'shared';
import { Grid, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import CoaNestedDropdown from './CoaNestedDropdown';
import { formatAccountTitleRow } from 'utils/helpers';
import { rowAccordionStyles } from '../../style/style';
import MatchedTransactionInfo from './MatchedTransactionInfo';

const validationSchema = Yup.object({
    merchantName: Yup.string().required('Merchant Name is required'),
});

const CreditCardPaymentForm = ({
    data,
    coaCategoryData,
    matchedTransaction,
    handleUpdateTransaction,
    handleUnmatchTransaction,
}) => {
    const classes = rowAccordionStyles();

    const [selectedOption, setSelectedOption] = useState({
        accountNumber: data?.coa_account?.id,
        title: formatAccountTitleRow(data?.coa_account),
    });
    const [importLoading, setImportLoading] = useState(false);

    const handleFormSubmit = (values) => {
        setImportLoading(true);
        handleUpdateTransaction({
            merchantName: values.merchantName,
            coaAccountId: selectedOption.accountNumber,
        });
    };

    return (
        <Formik
            initialValues={{
                merchantName: data.merchant_name || '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleFormSubmit(values)}
        >
            {({ values, handleChange, errors, touched, handleBlur }) => (
                <Form>
                    <Fragment>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <CoaNestedDropdown
                                    selectedOption={selectedOption}
                                    coaCategoryData={coaCategoryData}
                                    setSelectedOption={setSelectedOption}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} />
                            <Grid item xs={12} md={6}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    name="merchantName"
                                    placeholder="Merchant Name"
                                    value={values.merchantName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.merchantName &&
                                        Boolean(errors.merchantName)
                                    }
                                    helperText={
                                        touched.merchantName &&
                                        errors.merchantName
                                    }
                                />
                            </Grid>
                            {matchedTransaction && (
                                <Grid item xs={10}>
                                    <MatchedTransactionInfo
                                        transaction={matchedTransaction}
                                        handleUnmatchTransaction={
                                            handleUnmatchTransaction
                                        }
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="flex-end">
                                    <LoadingButton
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        sx={classes.importButton}
                                        loading={importLoading}
                                    >
                                        Import
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Fragment>
                </Form>
            )}
        </Formik>
    );
};

export default CreditCardPaymentForm;
