export const inventoryReconciliationStyles = () => ({
    inventoryReconciliationWrapper: {
        background: (theme) =>
            theme.palette.mode == 'dark' ? '#212936' : 'white',
        padding: '24px',
        borderRadius: '12px',
        marginBottom: '20px',
    },
    buttonsWrapper: {
        display: 'flex',
        gap: '20px',
        marginTop: '20px',
        justifyContent: 'flex-end',
    },
    button: {
        color: 'white',
    },
    dropdownsWrapper: {
        display: 'flex',
        gap: '20px',
        width: '100%',
        marginBlock: '10px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    flex: {
        flex: 1,
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '300px',
        width: '100%',
    },
});
