export const makeStyles = ({ dataId, selectedBankId }) => ({
    bankCardWrapper: {
        border: (theme) =>
            `1px solid ${
                dataId == selectedBankId
                    ? '#0061DB'
                    : theme.palette.mode === 'dark'
                    ? 'transparent'
                    : '#E5E6EB'
            } `,
        backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? 'transparent' : '#F9FAFB',
    },
    bankCardContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    iconStyle: { fontSize: '18px', color: '#6C727F' },
    bankNameContainer: {
        flex: 1,
        minWidth: 0,
    },
});

export const balanceTextStyle = {
    fontSize: '14px',
    fontWeight: '400',
};

export const bankNameStyle = { fontSize: '16px', fontWeight: '600' };

export const bankNameOverflow = {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
};

export const balanceStyle = {
    fontSize: '20px',
    fontWeight: '700',
};

export const bankCarouselMakeStyles = ({
    currentIndex,
    cardsPerPage,
    shouldShowArrows,
}) => ({
    carouselWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
    },
    leftArrowButton: { position: 'absolute', left: 0, zIndex: 1 },
    rightArrowButton: { position: 'absolute', right: 0, zIndex: 1 },
    bankCardsWrapper: {
        display: 'flex',
        transform: `translateX(-${currentIndex * (100 / cardsPerPage)}%)`,
        transition: 'transform 0.3s ease-in-out',
        width: shouldShowArrows ? '90%' : '100%',
    },
    bankCardWrapper: {
        flex: `0 0 ${100 / cardsPerPage}%`,
        padding: '8px',
    },
});

export const syncBankAccountsMakeStyles = ({}) => ({
    iconStyle: { fontSize: '18px', color: '#6C727F' },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '300px',
    },
});

export const bankTransactionsMakeStyles = ({}) => ({
    tabsStack: {
        background: 'transparent',
        minHeight: '60px',
        borderRadius: '5px',
        padding: '1rem 2rem',
    },
});

export const makeTableNewStyles = () => ({
    smDownBoxWrapper: {
        p: 2,
        borderRadius: '10px',
        border: (theme) =>
            theme.palette.mode === 'dark'
                ? '2px solid #394150'
                : '1px solid #E5E6EB',
    },
    boldFont: {
        fontWeight: 'bold',
    },
    formControlStyle: {
        m: 1,
    },
    tableHeader: {
        textAlign: 'center',
        padding: '16px 10px',
    },
    tableBody: {
        borderBottom: 'none',
        textAlign: 'center',
        padding: '16px 10px',
    },
    companySelect: {
        '& .MuiSelect-select': {
            padding: 0,
            minHeight: 'unset',
            lineHeight: 'inherit',
            display: 'flex',
            alignItems: 'center',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        cursor: 'pointer',
        borderRadius: '12px',
        '&::before': {
            borderBottom: 'none',
        },
        '&::after': {
            borderBottom: 'none',
        },
        '& .MuiSelect-root': {
            borderBottom: 'none',
        },
        '&:hover:not(.Mui-disabled)::before': {
            borderBottom: 'none',
        },
    },
    importButton: {
        backgroundColor: '#0061DB',
        color: 'white',
        borderRadius: '8px',
        paddingX: '10px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#0056b3',
            '@media (hover: hover)': {
                backgroundColor: '#0056b3',
            },
        },
    },
    rootWrapper: {
        border: (theme) =>
            theme.palette.mode === 'dark'
                ? '1px solid #394150'
                : '1px solid #E5E6EB',
        mb: 2,
        p: '15px',
    },
    accordionExpandIcon: {
        borderRadius: '50%',
        backgroundColor: 'white',
        color: '#6C727F',
        width: '30px',
        height: '30px',
    },
    accordionSummary: {
        bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#394150' : '#E5E6EB',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
    },
    accountName: {
        fontWeight: 600,
        fontSize: '14px',
        color: (theme) =>
            theme.palette.mode === 'dark' ? '#9EA3AE' : '#4D5461',
    },
    amountSelect: {
        '& .MuiSelect-select': {
            padding: 0,
            minHeight: 'unset',
            lineHeight: 'inherit',
            display: 'flex',
            alignItems: 'center',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        cursor: 'pointer',
        borderRadius: '12px',
    },
    attachImportButtonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    attachButton: {
        border: (theme) =>
            theme.palette.mode === 'dark'
                ? '2px solid #394150'
                : '1px solid #E5E6EB',
        color: (theme) =>
            theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F',
        borderRadius: '8px',
        textTransform: 'none',
        my: 1,
        flex: 1,
        mr: 0.5,
    },
    smDownImportButton: {
        border: (theme) =>
            theme.palette.mode === 'dark'
                ? '2px solid #394150'
                : '1px solid #E5E6EB',
        color: '#0061DB',
        borderRadius: '8px',
        textTransform: 'none',
        my: 1,
        flex: 1,
        ml: 0.5,
    },
    noBorder: {
        borderBottom: 'none',
    },
    accordionBackground: { backgroundColor: 'transparent' },
    rowAccordionWrapper: { border: 'none', padding: 0, margin: 0 },
});

export const rowAccordionStyles = () => ({
    root: {
        backgroundColor: (theme) =>
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.05)'
                : '#F9FAFB',
        p: '20px',
        border: (theme) =>
            `1px solid ${
                theme.palette.mode === 'dark'
                    ? 'rgba(81, 81, 81, 1)'
                    : '#E5E6EB'
            } `,
        borderRadius: '5px',
    },
    radioButtonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 2,
    },
    radioGroup: { gap: 2, fontSize: '2px' },
    radioButtonLabel: {
        '& .MuiTypography-root': { fontSize: '14px' },
    },
    radioButtonIcon: {
        '& .MuiSvgIcon-root': {
            fontSize: '14px',
        },
    },
    crossIcon: {
        padding: '6px',
        '& .MuiSvgIcon-root': { fontSize: '20px' },
    },
    importButton: {
        backgroundColor: '#0061DB',
        color: 'white',
        borderRadius: '8px',
        paddingX: '10px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#0056b3',
            '@media (hover: hover)': {
                backgroundColor: '#0056b3',
            },
        },
    },
});

export const coaNestedDropdownStyles = () => ({
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px',
    },
    nestedDropdown: {
        fontSize: '13px',
        fontWeight: 400,
        padding: '14px 14px',
        color: (theme) =>
            theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F',
        textAlign: 'left',
        backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#394150' : '#F4F4F6',
        border: `1px solid ${(theme) =>
            theme.palette.mode === 'dark' ? '#394150' : '#E5E6EB'}`,
        borderRadius: '6px',
        textTransform: 'none',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:hover': {
            borderColor: '#6C727F',
        },
    },
});

export const matchTransactionInfoStyles = () => ({
    rootWrapper: {
        border: (theme) =>
            `1px solid ${
                theme.palette.mode === 'dark'
                    ? 'rgba(81, 81, 81, 1)'
                    : '#E5E6EB'
            } `,
        padding: '16px 8px',
        borderRadius: '15px',
        backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#394150' : '#F4F4F6',
        padding: '20px',
    },
    category: { mt: 1 },
    unmatchButton: {
        backgroundColor: '#0061DB',
        color: 'white',
        borderRadius: '8px',
        paddingX: '10px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#0056b3',
            '@media (hover: hover)': {
                backgroundColor: '#0056b3',
            },
        },
    },
});
