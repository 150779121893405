import React from 'react';

import { Grid } from '@mui/material';

import SyncBankAccounts from './SyncBankAccounts';

const ImportBankTransaction = () => {
    return (
        <Grid
            item
            xs={12}
            p={2}
            borderRadius={3}
            sx={{
                bgcolor: (theme) =>
                    theme.palette.mode === 'dark' ? '#212936' : 'white',
            }}
        >
            <SyncBankAccounts />
        </Grid>
    );
};

export default ImportBankTransaction;
