import { useSelector } from 'react-redux';
import React, { useState, Fragment, useEffect } from 'react';

import Alert from '@mui/material/Alert';
import { Button, Typography } from 'shared';
import AddIcon from '@mui/icons-material/Add';
import Snackbar from '@mui/material/Snackbar';
import { Box, CircularProgress } from '@mui/material';

import JournalForm from './components/JournalForm';
import JournalTable from './components/JournalTable';
import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { useBookkeeperService } from 'store/services/bookkeeper.service';
import { journalButton, journalButtonText, makeStyles } from './style/style';

const Journal = ({}) => {
    const companyData = useSelector((state) => state.company.data);
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');
    const { getManualJournalEntry, isLoading } = useBookkeeperService();
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');

    const classes = makeStyles({ isScreenLowerThan1024 });

    const [page, setPage] = useState(1);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openJournalForm, setOpenJournalForm] = useState(false);
    const [manualJournalEntries, setManualJournalEntries] = useState([]);

    const handleJournalCreationSuccess = () => {
        setOpenSnackBar(true);
        setOpenJournalForm(false);
        handleManualJournalEntry(page);
    };

    const handleManualJournalEntry = async (page) => {
        const response = await getManualJournalEntry(companyData.id, page);
        setManualJournalEntries(response);
    };

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
        handleManualJournalEntry(pageNumber);
    };

    useEffect(() => {
        if (companyData) {
            handleManualJournalEntry(page);
        }
    }, [companyData]);

    return (
        <Box sx={classes.root}>
            {companyData ? (
                <Fragment>
                    <Box sx={classes.buttonWrapper}>
                        <Button
                            text={
                                <Typography
                                    text={'Add Journal Entry'}
                                    customStyles={journalButtonText}
                                />
                            }
                            startIcon={<AddIcon htmlColor={'#FFF'} />}
                            customStyles={journalButton(isMobileScreen)}
                            onClick={() => setOpenJournalForm(true)}
                        />
                    </Box>
                    <Box>
                        <JournalTable
                            page={page}
                            loading={isLoading}
                            data={manualJournalEntries}
                            handlePageChange={handlePageChange}
                        />
                    </Box>
                    {openJournalForm && (
                        <JournalForm
                            type="Add"
                            open={openJournalForm}
                            companyId={companyData.id}
                            handleCloseModal={() => setOpenJournalForm(false)}
                            handleJournalCreationSuccess={
                                handleJournalCreationSuccess
                            }
                        />
                    )}
                    <Snackbar
                        open={openSnackBar}
                        autoHideDuration={6000}
                        onClose={() => setOpenSnackBar(false)}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert
                            onClose={() => setOpenSnackBar(false)}
                            severity="success"
                            variant="filled"
                            sx={classes.alert}
                        >
                            Manual entry added successfully
                        </Alert>
                    </Snackbar>
                </Fragment>
            ) : (
                <Box sx={classes.loader}>
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};

export default Journal;
