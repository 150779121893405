import React, { Fragment, useState } from 'react';

import {
    Box,
    Link,
    Table,
    Button,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    Accordion,
    Typography,
    IconButton,
    TableContainer,
    AccordionDetails,
    AccordionSummary,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Animation from '../Animation';
import RowAccordion from './RowAccordion';
import CustomPagination from '../TablePagination';
import NestedDropDown from './NestedDropDown.jsx';
import { makeTableNewStyles } from '../../style/style';
import { formatAccountTitleRow, formatCurrencyAmount } from 'utils/helpers';

const TableNew = ({
    allRows,
    expanded,
    accountId,
    handleAccordionChange,
    accountName,
    smDown,
    attach,
    attachedTransactions,
    handleImport,
    handleChangePage,
    handleUnimport,
    totalPages,
    rowsPerPage,
    currentPage,
    panelData,
    onListItemClick,
    changeDropDownLength,
    isPanelDataLoading,
    openDropDownIndex,
    handleDropDownToggle,
    handleDropDownClose,
    bankTransactionsTabsInfo,
    handleBankTransactions,
}) => {
    const classes = makeTableNewStyles();

    const [expandedRowId, setExpandedRowId] = useState(null);

    const handleImportUnimport = (imported, id, coa_id) => {
        if (imported) {
            handleUnimport(id, coa_id);
        } else {
            handleImport(id, coa_id);
        }
    };

    const toggleAccordion = (e, rowId) => {
        e.preventDefault();
        setExpandedRowId((prevRowId) => (prevRowId === rowId ? null : rowId));
    };

    const DisplayContent = ({ transactions, account }) => {
        return smDown ? (
            <Box>
                {transactions?.map((row, index) => (
                    <Box key={row.id} sx={classes.smDownBoxWrapper} mb={2}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="body2" sx={classes.boldFont}>
                                {row.date}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="body2">
                                {row.merchant_name}
                            </Typography>

                            <Typography>
                                {row?.status.replace(/^\w/, (c) =>
                                    c.toUpperCase(),
                                )}{' '}
                                to{' '}
                                {row?.imported ? (
                                    row?.transaction_type
                                        .replace(/_/g, ' ')
                                        .replace(/\b\w/g, (char) =>
                                            char.toUpperCase(),
                                        )
                                ) : (
                                    <Link
                                        href="#"
                                        onClick={(e) => {
                                            toggleAccordion(e, row.id);
                                        }}
                                    >
                                        {row?.transaction_type
                                            .replace(/_/g, ' ')
                                            .replace(/\b\w/g, (char) =>
                                                char.toUpperCase(),
                                            )}
                                    </Link>
                                )}
                            </Typography>
                        </Box>

                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={3}
                        >
                            <Typography variant="body2" sx={classes.boldFont}>
                                ${formatCurrencyAmount(row?.amount || 0)}
                            </Typography>

                            <NestedDropDown
                                item={panelData}
                                title={formatAccountTitleRow(row?.coa_account)}
                                plaidId={row?.id}
                                companyId={row?.company?.id}
                                onItemClick={onListItemClick}
                                rowIndex={index}
                                transactionId={row?.id}
                                changeDropDownLength={changeDropDownLength}
                                isDropDownOpen={row?.id === openDropDownIndex}
                                handleDropDownToggle={handleDropDownToggle}
                                isPanelDataLoading={isPanelDataLoading}
                                handleDropDownClose={handleDropDownClose}
                            />
                        </Box>
                        <Box sx={classes.attachImportButtonsWrapper}>
                            <Button
                                sx={classes.attachButton}
                                onClick={() =>
                                    attach(row.id, accountId(account))
                                }
                            >
                                {attachedTransactions?.find(
                                    (item) =>
                                        item?.plaid_transaction === row.id,
                                )
                                    ? 'View Attached'
                                    : 'Attach'}
                            </Button>

                            <Button
                                sx={classes.smDownImportButton}
                                onClick={() =>
                                    handleImportUnimport(
                                        row?.imported,
                                        row.id,
                                        row?.coa_account?.id,
                                    )
                                }
                            >
                                {row?.imported ? 'Unimport' : 'Import'}
                            </Button>
                        </Box>
                        {expandedRowId === row.id && row?.imported == false && (
                            <RowAccordion
                                rowData={row}
                                onClose={() => setExpandedRowId(null)}
                                handleBankTransactions={handleBankTransactions}
                            />
                        )}
                    </Box>
                ))}
            </Box>
        ) : (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={classes.tableHeader}>Date</TableCell>
                            <TableCell sx={classes.tableHeader}>
                                Description
                            </TableCell>
                            <TableCell sx={classes.tableHeader}>
                                Amount
                            </TableCell>
                            <TableCell sx={classes.tableHeader}>Type</TableCell>
                            <TableCell sx={classes.tableHeader}>
                                Category
                            </TableCell>
                            <TableCell sx={classes.tableHeader}>
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions?.length == 0 ? (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    <Typography
                                        variant="body1"
                                        color="textSecondary"
                                    >
                                        No data available
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            transactions?.map((row, index) => (
                                <Fragment>
                                    <TableRow
                                        key={row.id}
                                        sx={classes.noBorder}
                                    >
                                        <TableCell sx={classes.tableBody}>
                                            <Typography variant="body2" noWrap>
                                                {row.date}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={classes.tableBody}>
                                            <Typography variant="body2">
                                                {row.merchant_name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={classes.tableBody}>
                                            <Typography sx={classes.boldFont}>
                                                $
                                                {formatCurrencyAmount(
                                                    row?.amount || 0,
                                                )}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={classes.tableBody}>
                                            <Typography variant="body2" noWrap>
                                                {row?.status.replace(
                                                    /^\w/,
                                                    (c) => c.toUpperCase(),
                                                )}{' '}
                                                to{' '}
                                                {row?.imported ? (
                                                    row?.transaction_type
                                                        .replace(/_/g, ' ')
                                                        .replace(
                                                            /\b\w/g,
                                                            (char) =>
                                                                char.toUpperCase(),
                                                        )
                                                ) : (
                                                    <Link
                                                        href="#"
                                                        onClick={(e) => {
                                                            toggleAccordion(
                                                                e,
                                                                row.id,
                                                            );
                                                        }}
                                                    >
                                                        {row?.transaction_type
                                                            .replace(/_/g, ' ')
                                                            .replace(
                                                                /\b\w/g,
                                                                (char) =>
                                                                    char.toUpperCase(),
                                                            )}
                                                    </Link>
                                                )}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={classes.tableBody}>
                                            <NestedDropDown
                                                item={panelData}
                                                title={formatAccountTitleRow(
                                                    row?.coa_account,
                                                )}
                                                plaidId={row?.id}
                                                companyId={row?.company?.id}
                                                onItemClick={onListItemClick}
                                                rowIndex={index}
                                                transactionId={row?.id}
                                                changeDropDownLength={
                                                    changeDropDownLength
                                                }
                                                isDropDownOpen={
                                                    row?.id ===
                                                    openDropDownIndex
                                                }
                                                handleDropDownToggle={
                                                    handleDropDownToggle
                                                }
                                                isPanelDataLoading={
                                                    isPanelDataLoading
                                                }
                                                handleDropDownClose={
                                                    handleDropDownClose
                                                }
                                            />
                                        </TableCell>
                                        <TableCell sx={classes.tableBody}>
                                            <Button
                                                size="small"
                                                sx={classes.importButton}
                                                onClick={() =>
                                                    handleImportUnimport(
                                                        row?.imported,
                                                        row.id,
                                                        row?.coa_account?.id,
                                                    )
                                                }
                                            >
                                                {row?.imported
                                                    ? 'Unimport'
                                                    : 'Import'}
                                            </Button>
                                        </TableCell>
                                    </TableRow>

                                    {expandedRowId === row.id &&
                                        row?.imported == false && (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={6}
                                                    sx={
                                                        classes.rowAccordionWrapper
                                                    }
                                                >
                                                    <RowAccordion
                                                        rowData={row}
                                                        onClose={() =>
                                                            setExpandedRowId(
                                                                null,
                                                            )
                                                        }
                                                        handleBankTransactions={
                                                            handleBankTransactions
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                </Fragment>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <Fragment>
            {allRows ? (
                Object?.entries(allRows)?.map(([account, transactions]) => (
                    <Box
                        key={account}
                        border="1px solid #E5E6EB"
                        borderRadius={2}
                        sx={classes.rootWrapper}
                    >
                        {bankTransactionsTabsInfo ? (
                            <DisplayContent
                                transactions={transactions}
                                account={account}
                            />
                        ) : (
                            <Accordion
                                expanded={expanded.includes(accountId(account))}
                                onChange={handleAccordionChange(
                                    accountId(account),
                                )}
                                aria-controls={`${accountId(account)}-content`}
                                id={`${accountId(account)}-header`}
                                sx={classes.accordionBackground}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <IconButton
                                            sx={classes.accordionExpandIcon}
                                        >
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    }
                                    sx={classes.accordionSummary}
                                >
                                    <Typography sx={classes.accountName}>
                                        {accountName(account)}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <DisplayContent
                                        transactions={transactions}
                                        account={account}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        )}
                        <CustomPagination
                            count={(totalPages[account] || 1) * rowsPerPage}
                            page={(currentPage[account] || 1) - 1}
                            rowsPerPage={rowsPerPage}
                            onPageChange={(event, newPage) =>
                                handleChangePage(account, event, newPage)
                            }
                        />
                    </Box>
                ))
            ) : (
                <Animation />
            )}
        </Fragment>
    );
};

export default TableNew;
