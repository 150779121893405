import Papa from 'papaparse';
import { useSelector } from 'react-redux';
import React, { useCallback, Fragment, useState } from 'react';

import {
    Box,
    Grid,
    Alert,
    Select,
    MenuItem,
    Snackbar,
    Typography,
    FormControl,
    CircularProgress,
} from '@mui/material';

import { Upload } from 'components/upload';
import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { Button, DataTable, TablePagination } from 'shared';
import { inventoryReconciliationStyles } from './style/style';
import { useInventoryService } from 'store/services/inventory.service';

const customHeaders = [
    'Reference id',
    'Order id',
    'Description',
    'Class 1 (Section, category)',
    'Class 2 (Row, sub-category)',
    'Class 3 (Seat, sub-detail) ',
    'Purchase Price',
    'Expiration date',
    'Purchase date',
];

const InventoryReconciliation = () => {
    const classes = inventoryReconciliationStyles();
    const { isLoading, addFileInventory } = useInventoryService();
    const companyData = useSelector((state) => state.company.data);
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');

    const [page, setPage] = useState(1);
    const [rows, setRows] = useState([]);
    const [files, setFiles] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [alertData, setAlertData] = useState({
        severity: '',
        message: '',
        open: false,
    });
    const [selectedValues, setSelectedValues] = useState({});

    const handleFileDrop = useCallback(
        (acceptedFiles) => {
            const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            );

            setFiles([newFiles[0]]);
            if (newFiles) {
                Papa.parse(newFiles[0], {
                    header: true,
                    skipEmptyLines: true,
                    complete: (results) => {
                        if (results.data.length > 0) {
                            const firstRow = results.data[0];
                            const formatted = Object.keys(firstRow).map(
                                (header) => ({
                                    id: header,
                                    label: header,
                                    sort: false,
                                    verticalAlign: 'top',
                                    changed: false,
                                    labelForSelect: header,
                                }),
                            );
                            setHeaders(formatted);
                            setRows(results.data);
                        }
                    },
                    error: (error) => {
                        console.error('Error parsing CSV:', error);
                    },
                });
            }
        },
        [files],
    );

    const handleHeaderChange = (event, ch) => {
        const selectedValue = event.target.value;

        setHeaders((prevHeaders) =>
            prevHeaders.map((header) => {
                if (header.id === selectedValues[ch]) {
                    return {
                        ...header,
                        changed: false,
                        label: header.label.replace(` (${ch})`, ''),
                    };
                }
                if (header.id === selectedValue) {
                    return {
                        ...header,
                        changed: true,
                        label: `${header.label} (${ch})`,
                    };
                }
                return header;
            }),
        );

        setSelectedValues((prevSelectedValues) => ({
            ...prevSelectedValues,
            [ch]: selectedValue,
        }));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleClear = () => {
        setFiles([]);
        setHeaders([]);
        setRows([]);
        setSelectedValues({});
        setPage(1);
    };

    const formatData = () => {
        const camelCaseKey = (str) => {
            return str
                .replace(/\([^)]*\)/g, '') // Remove anything inside parentheses, including the parentheses
                .replace(/(?:^|\s)(\w)/g, (_, c) => (c ? c.toUpperCase() : '')) // Capitalize letters after spaces
                .replace(/\s+/g, '') // Remove spaces
                .replace(/^(\w)/, (_, c) => c.toLowerCase()); // Make the first letter lowercase
        };

        return rows.map((row) => {
            const formattedRow = {};

            Object.keys(selectedValues).forEach((key) => {
                const mappedKey = camelCaseKey(key);
                const mappedValue = selectedValues[key];

                formattedRow[mappedKey] = row[mappedValue] || null;
            });

            return formattedRow;
        });
    };

    const handleImport = async () => {
        const data = formatData();
        try {
            const response = await addFileInventory({
                data,
                companyId: companyData.id,
            });
            console.log(response);
            setAlertData({
                severity: 'success',
                message: response.message,
                open: true,
            });
            handleClear();
        } catch (error) {
            setAlertData({
                severity: 'error',
                message: 'Something went wrong, please try again',
                open: true,
            });
        }
    };

    const handleAlertClose = () => {
        setAlertData((prevState) => ({
            ...prevState,
            open: false,
        }));
    };

    if (!companyData) {
        return (
            <Box sx={classes.loader}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Fragment>
            <Grid container sx={classes.inventoryReconciliationWrapper}>
                <Fragment>
                    <Grid item xs={12} width={'100%'}>
                        <Upload
                            multiple
                            accept={{
                                'text/csv': [],
                                'application/vnd.ms-excel': [],
                            }}
                            placeholder={'Drag and drop your files here (CSV)'}
                            files={files}
                            onDrop={handleFileDrop}
                            onRemove={handleClear}
                        />
                        {headers.length > 0 && (
                            <Box sx={classes.buttonsWrapper}>
                                <Button
                                    text="Import"
                                    variant="outlined"
                                    disabled={
                                        Object.keys(selectedValues)?.length <
                                        customHeaders.length
                                    }
                                    customStyles={classes.button}
                                    onClick={handleImport}
                                    loading={isLoading}
                                />
                                <Button
                                    text="Clear"
                                    onClick={handleClear}
                                    customStyles={classes.button}
                                />
                            </Box>
                        )}
                    </Grid>
                    {files.length > 0 && (
                        <Grid container marginTop={5}>
                            <Grid item sm={12} md={6}>
                                <Typography>
                                    Total Rows: {rows.length}
                                </Typography>
                            </Grid>
                            {headers.length > 0 && (
                                <Grid item sm={12} md={6}>
                                    {customHeaders.map((ch, i) => (
                                        <Box
                                            key={i}
                                            sx={classes.dropdownsWrapper}
                                        >
                                            <Typography sx={classes.flex}>
                                                {ch}
                                            </Typography>
                                            <FormControl sx={classes.flex}>
                                                <Select
                                                    placeholder="Select Column"
                                                    value={
                                                        selectedValues[ch] || ''
                                                    }
                                                    onChange={(e) =>
                                                        handleHeaderChange(
                                                            e,
                                                            ch,
                                                        )
                                                    }
                                                >
                                                    {headers
                                                        .filter(
                                                            (h) =>
                                                                !h.changed ||
                                                                selectedValues[
                                                                    ch
                                                                ] === h.id,
                                                        )
                                                        .map((h) => (
                                                            <MenuItem
                                                                disabled={
                                                                    h.changed
                                                                }
                                                                key={h.id}
                                                                value={h.id}
                                                            >
                                                                {
                                                                    h.labelForSelect
                                                                }
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    ))}
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Fragment>
            </Grid>

            {headers.length > 0 && (
                <Grid container>
                    <Grid item xs={12}>
                        <DataTable
                            headCells={headers}
                            data={rows.slice((page - 1) * 10, page * 10)}
                        ></DataTable>
                    </Grid>
                    <Grid item xs={12} p={'10px 0'}>
                        <TablePagination
                            page={page}
                            count={rows.length}
                            isMobileScreen={isMobileScreen}
                            onChange={handleChangePage}
                        />
                    </Grid>
                </Grid>
            )}

            <Snackbar
                open={alertData.open}
                autoHideDuration={5000}
                onClose={handleAlertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertData.severity}
                    sx={{ width: '100%' }}
                >
                    {alertData.message}
                </Alert>
            </Snackbar>
        </Fragment>
    );
};

export default InventoryReconciliation;
