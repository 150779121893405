// sagas/bankSagas.js
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    getBankAccounts,
    generateLinkToken,
    setAccessToken,
    fetchTransactions,
    importTransactions,
    unimportTransactions,
    syncInitialTransactions,
    fetchBankTransactions,
    attachTransaction,
    fetchUnmatchedTransactions,
    matchTransactions,
    fetchMatchedTransactions,
    inputEndingBalance,
    fetchReconciliationTotals,
    syncTransactions,
} from '../services/bank.Service';
import {
    fetchBankAccountsRequest,
    fetchBankAccountsSuccess,
    fetchBankAccountsFailure,
    fetchLinkTokenRequest,
    fetchLinkTokenSuccess,
    fetchLinkTokenFailure,
    setAccessTokenRequest,
    setAccessTokenSuccess,
    setAccessTokenFailure,
    fetchTransactionsRequest,
    fetchTransactionsSuccess,
    fetchTransactionsFailure,
    importTransactionsRequest,
    importTransactionsSuccess,
    importTransactionsFailure,
    unimportTransactionsRequest,
    unimportTransactionsSuccess,
    unimportTransactionsFailure,
    syncInitialTransactionsRequest,
    syncInitialTransactionsSuccess,
    syncInitialTransactionsFailure,
    fetchBankTransactionsRequest,
    fetchBankTransactionsSuccess,
    fetchBankTransactionsFailure,
    attachTransactionRequest,
    attachTransactionSuccess,
    attachTransactionFailure,
    fetchUnmatchedTransactionsRequest,
    fetchUnmatchedTransactionsSuccess,
    fetchUnmatchedTransactionsFailure,
    fetchMatchedTransactionsRequest,
    fetchMatchedTransactionsSuccess,
    fetchMatchedTransactionsFailure,
    fetchmatchedTransactionsRequest,
    fetchmatchedTransactionsSuccess,
    fetchmatchedTransactionsFailure,
    inputEndingBalanceRequest,
    inputEndingBalanceSuccess,
    inputEndingBalanceFailure,
    fetchReconciliationTotalsRequest,
    fetchReconciliationTotalsSuccess,
    fetchReconciliationTotalsFailure,
    syncTransactionsSuccess,
    syncTransactionsFailure,
    syncTransactionsRequest,
} from '../slices/bankAccountsSlice';
import { SET_TRANSACTION_TYPE } from 'store/actions';

function* fetchBankAccountsSaga(action) {
    const { companyId, token } = action.payload;
    try {
        const accounts = yield call(getBankAccounts, companyId, token);
        yield put(fetchBankAccountsSuccess(accounts));
    } catch (error) {
        yield put(fetchBankAccountsFailure(error.message));
    }
}

function* fetchLinkTokenSaga(action) {
    const {
        token,
        start_date,
        handleAddDateModalClose,
        setIsCreateLinkLoading,
        handleSyncClick,
        setLinkToken2,
    } = action.payload;
    try {
        const linkToken = yield call(generateLinkToken, token, start_date);
        yield put(fetchLinkTokenSuccess(linkToken));
        if (typeof setLinkToken2 === 'function') {
            setLinkToken2(linkToken);
        }
    } catch (error) {
        yield put(fetchLinkTokenFailure(error.message));
    } finally {
        if (typeof handleAddDateModalClose === 'function') {
            handleAddDateModalClose();
        }
        if (typeof setIsCreateLinkLoading === 'function') {
            setIsCreateLinkLoading(false);
        }
        if (typeof handleSyncClick === 'function') {
            handleSyncClick();
        }
    }
}

function* setAccessTokenSaga(action) {
    const { publicToken, token, fetchBankAccounts, companyId, setIsLoading } =
        action.payload;
    try {
        if (typeof setIsLoading === 'function') {
            setIsLoading(true);
        }
        const accessToken = yield call(setAccessToken, publicToken, token);
        const data = yield call(
            syncInitialTransactions,
            null,
            accessToken?.plaid_item_id,
            token,
        );
        yield put(syncInitialTransactionsSuccess(data));
        yield put(setAccessTokenSuccess(accessToken));
        const accounts = yield call(getBankAccounts, companyId, token);
        yield put(fetchBankAccountsSuccess(accounts));
        yield put(SET_TRANSACTION_TYPE('INITIAL'));
        if (typeof fetchBankAccounts === 'function') {
            yield fetchBankAccounts();
            setIsLoading(false);
        }
    } catch (error) {
        yield put(setAccessTokenFailure(error.message));
    }
}

function* fetchTransactionsSaga(action) {
    const { account, page, token } = action.payload;
    try {
        const transactions = yield call(
            fetchTransactions,
            account,
            page,
            token,
        );
        yield put(fetchTransactionsSuccess(transactions));
    } catch (error) {
        yield put(fetchTransactionsFailure(error.message));
    }
}

function* importTransactionsSaga(action) {
    const {
        token,
        companyId,
        transactionIds,
        setSnackbarOpen,
        setSnackbarMessage,
        setIsImportAllLoading,
        handleChangePageAfterImportAll,
    } = action.payload;
    try {
        const response = yield call(
            importTransactions,
            token,
            companyId,
            transactionIds,
        );
        setSnackbarOpen(true);
        setSnackbarMessage(response?.data?.detail);
        yield put(importTransactionsSuccess());
        if (typeof handleChangePageAfterImportAll === 'function') {
            handleChangePageAfterImportAll();
        }
    } catch (error) {
        yield put(importTransactionsFailure(error.message));
    } finally {
        if (typeof setIsImportAllLoading === 'function') {
            setIsImportAllLoading(false);
        }
    }
}

function* unimportTransactionsSaga(action) {
    const {
        token,
        companyId,
        transactionIds,
        setSnackbarOpen,
        setSnackbarMessage,
    } = action.payload;
    try {
        const response = yield call(
            unimportTransactions,
            token,
            companyId,
            transactionIds,
        );
        setSnackbarOpen(true);
        setSnackbarMessage(response?.data?.detail);
        yield put(unimportTransactionsSuccess());
    } catch (error) {
        yield put(unimportTransactionsFailure(error.message));
    }
}

function* syncInitialTransactionsSaga(action) {
    const {
        formattedDate,
        plaidId,
        token,
        setShowAlertMessage,
        setShowAlert,
        setIsImportLoading,
        onSyncAll,
        result,
        setImportTransaction,
        setIsLoading,
    } = action.payload;
    try {
        if (typeof setIsLoading === 'function') {
            setIsLoading(true);
        }
        const data = yield call(
            syncInitialTransactions,
            formattedDate,
            plaidId,
            token,
        );
        // setShowAlertMessage(data?.code);
        // setShowAlert('SUCCESS');
        yield put(syncInitialTransactionsSuccess(data));
        // if (typeof onSyncAll === 'function') {
        //     yield put(SET_TRANSACTION_TYPE('INITIAL'));
        // }
    } catch (error) {
        yield put(syncInitialTransactionsFailure(error.message));
    } finally {
        // if (typeof setIsImportLoading === 'function') {
        //     setIsImportLoading(false);
        // }
        // if (typeof onSyncAll === 'function') {
        //     onSyncAll(1, result, 'INITIAL');
        //     setImportTransaction(false);
        // }
    }
}

function* fetchBankTransactionsSaga(action) {
    const { accountId, page, token, onSyncAll, result, status } =
        action.payload;
    try {
        const transactions = yield call(
            fetchBankTransactions,
            accountId,
            page,
            token,
            status,
        );
        yield put(fetchBankTransactionsSuccess(transactions));
    } catch (error) {
        yield put(fetchBankTransactionsFailure(error.message));
    } finally {
        if (typeof onSyncAll === 'function') {
            yield put(SET_TRANSACTION_TYPE('UN_IMPORT'));
            onSyncAll(2, result, 'UN_IMPORT');
        }
    }
}

function* attachTransactionSaga(action) {
    const { plaidTransaction, data, token } = action.payload;
    try {
        const response = yield call(
            attachTransaction,
            plaidTransaction,
            data,
            token,
        );
        yield put(attachTransactionSuccess(response));
    } catch (error) {
        yield put(attachTransactionFailure(error.message));
    }
}

function* fetchUnmatchedTransactionsSaga(action) {
    const {
        reconciliationId,
        page,
        pageSize,
        token,
        setIsDeleteLoading,
        setModal,
        setSelectedItemId2,
    } = action.payload;
    try {
        const unmatchedTransactions = yield call(
            fetchUnmatchedTransactions,
            reconciliationId,
            page,
            pageSize,
            token,
        );
        yield put(fetchUnmatchedTransactionsSuccess(unmatchedTransactions));
        if (typeof setSelectedItemId2 === 'function') {
            setSelectedItemId2(false);
        }
        if (typeof setIsDeleteLoading === 'function') {
            setIsDeleteLoading(false);
        }
        if (typeof setModal === 'function') {
            setModal(false);
        }
    } catch (error) {
        if (typeof setIsDeleteLoading === 'function') {
            setIsDeleteLoading(false);
        }
        yield put(fetchUnmatchedTransactionsFailure(error.message));
    }
}

function* fetchMatchedTransactionsSaga(action) {
    const { reconciliationId, page, pageSize, token } = action.payload;
    try {
        const matchedTransactions = yield call(
            fetchMatchedTransactions,
            reconciliationId,
            page,
            pageSize,
            token,
        );
        yield put(fetchMatchedTransactionsSuccess(matchedTransactions));
    } catch (error) {
        yield put(fetchMatchedTransactionsFailure(error.message));
    }
}

function* fetchmatchedTransactionsSaga(action) {
    const { plaid_transaction_id, transaction_id, token } = action.payload;
    try {
        const matchedTransaction = yield call(
            matchTransactions,
            plaid_transaction_id,
            transaction_id,
            token,
        );
        yield put(fetchmatchedTransactionsSuccess(matchedTransaction));
    } catch (error) {
        yield put(fetchmatchedTransactionsFailure(error.message));
    }
}

function* inputEndingBalanceSaga(action) {
    const {
        bankAccountId,
        endingBalance,
        beginningBalance,
        statementDate,
        token,
        onSyncAll,
        setIsInputLoading,
        setSnackbarOpen,
    } = action.payload;
    try {
        yield put(inputEndingBalanceSuccess(null));
        setIsInputLoading(true);
        const data = yield call(
            inputEndingBalance,
            bankAccountId,
            endingBalance,
            beginningBalance,
            statementDate,
            token,
        );
        if (data?.bank_beginning_balance) {
            yield put(inputEndingBalanceSuccess(data));
            setSnackbarOpen({
                type: 'success',
                message: 'Input received and saved successfully.',
            });
            onSyncAll('three');
        } else {
            throw new Error('Failed');
        }
    } catch (error) {
        yield put(inputEndingBalanceFailure(error?.message));
        setSnackbarOpen({
            type: 'error',
            message: 'Failed to Save!',
        });
    } finally {
        setIsInputLoading(false);
    }
}

function* fetchReconciliationTotalsSaga(action) {
    const { bankAccountId, companyId, token } = action.payload;
    try {
        const data = yield call(
            fetchReconciliationTotals,
            bankAccountId,
            companyId,
            token,
        );
        yield put(fetchReconciliationTotalsSuccess(data));
    } catch (error) {
        yield put(fetchReconciliationTotalsFailure(error.message));
    }
}

function* syncTransactionsSaga(action) {
    // Add this function
    const { token, fetchBankAccounts, setIsLoading } = action.payload;
    try {
        const data = yield call(syncTransactions, token);
        yield put(syncTransactionsSuccess(data));
    } catch (error) {
        yield put(syncTransactionsFailure(error.message));
    } finally {
        yield fetchBankAccounts();
        setIsLoading(false);
    }
}

export function* watchFetchBankAccounts() {
    yield takeLatest(fetchBankAccountsRequest.type, fetchBankAccountsSaga);
    yield takeLatest(fetchLinkTokenRequest.type, fetchLinkTokenSaga);
    yield takeLatest(setAccessTokenRequest.type, setAccessTokenSaga);
    yield takeLatest(fetchTransactionsRequest.type, fetchTransactionsSaga);

    yield takeLatest(importTransactionsRequest.type, importTransactionsSaga);
    yield takeLatest(
        unimportTransactionsRequest.type,
        unimportTransactionsSaga,
    );
    yield takeLatest(
        syncInitialTransactionsRequest.type,
        syncInitialTransactionsSaga,
    );
    yield takeLatest(
        fetchBankTransactionsRequest.type,
        fetchBankTransactionsSaga,
    );
    yield takeLatest(attachTransactionRequest.type, attachTransactionSaga);
    yield takeLatest(
        fetchUnmatchedTransactionsRequest.type,
        fetchUnmatchedTransactionsSaga,
    );
    yield takeLatest(
        fetchMatchedTransactionsRequest.type,
        fetchMatchedTransactionsSaga,
    );
    yield takeLatest(
        fetchmatchedTransactionsRequest.type,
        fetchmatchedTransactionsSaga,
    );
    yield takeLatest(inputEndingBalanceRequest.type, inputEndingBalanceSaga);
    yield takeLatest(
        fetchReconciliationTotalsRequest.type,
        fetchReconciliationTotalsSaga,
    );
    yield takeLatest(syncTransactionsRequest.type, syncTransactionsSaga);
}
