import { useCallback } from 'react';

import { useAxios } from '../../hooks/useAxios';

export const useQuickBooksService = () => {
    const { get, post, isLoading } = useAxios();

    const getQbLoginUrl = useCallback(() => get('/quickbooks/login/'), [get]);

    const getQbUserAndCompany = useCallback(
        (code, realmId) =>
            get(
                `/quickbooks/company-user-detail/?code=${code}&realmId=${realmId}`,
            ),
        [get],
    );

    const addQbAccounts = (realmId) =>
        post('/quickbooks/company-accounts/', { realm_id: realmId }, '');

    const importQbAccounts = (realmId) =>
        post('/quickbooks/import-company-accounts/', { realm_id: realmId }, '');

    const addQbBalanceSheet = (realmId, startDate) =>
        post(
            '/quickbooks/company-balance-sheet/',
            { realm_id: realmId, start_date: startDate },
            '',
        );

    const addTrialBalance = (realmId, startDate, endDate, companyId) =>
        post(
            '/quickbooks/company-trial-balance/',
            { realmId, startDate, endDate, companyId },
            '',
        );

    return {
        isLoading,
        getQbLoginUrl,
        addQbAccounts,
        addTrialBalance,
        importQbAccounts,
        addQbBalanceSheet,
        getQbUserAndCompany,
    };
};
