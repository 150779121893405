import dayjs from 'dayjs';
import { store } from 'store';
import { usePlaidLink } from 'react-plaid-link';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useDispatch, useSelector } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';

import {
    Box,
    Alert,
    Button,
    useTheme,
    Snackbar,
    Typography,
    useMediaQuery,
    CircularProgress,
} from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';

import {
    fetchLinkTokenRequest,
    setAccessTokenRequest,
    syncTransactionsRequest,
    fetchBankAccountsSuccess,
    fetchBankAccountsFailure,
    clearBankTransactions,
} from 'store/actions';
import ErrorAnimation from './Components/ErrorPopup';
import BankCardCarousel from './Components/BanksCarousel';
import AddBankStartDateModal from './AddBankStartDateModal';
import { syncBankAccountsMakeStyles } from './style/style';
import { getBankAccounts } from 'store/services/bank.Service';
import BankTransactionsStack from './Components/BankTransactionsStack';
import Animation from './Components/Animation';

dayjs.extend(isSameOrAfter);
const todayDate = dayjs(new Date()).format('YYYY-MM-DD');
const yesterdayDate = dayjs(todayDate).subtract(1, 'day');

const SyncBankAccounts = ({}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const classes = syncBankAccountsMakeStyles({});
    const { accounts } = useSelector((state) => state.bank);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { data: companyData } = useSelector((state) => state?.company);

    const [isLoading, setIsLoading] = useState(false);
    const [linkToken2, setLinkToken2] = useState(null);
    const [selectedBank, setSelectedBank] = useState(null);
    const [accountsError, setAccountsError] = useState(false);
    const [accountsErrorMsg, setAccountsErrorMsg] = useState('');
    const [snackbarMessage, setSnackbarMessage] = useState(false);
    const [selectedDate, setSelectedDate] = useState(yesterdayDate);
    const [isAddDateModalOpen, setAddDateModalOpen] = useState(false);
    const [bankAccountsLoading, setBankAccountsLoading] = useState(true);
    const [isCreateLinkLoading, setIsCreateLinkLoading] = useState(false);

    const handleAddDateModalClose = () => {
        setAddDateModalOpen(false);
    };

    const handleAddDateModalOpen = () => {
        setAddDateModalOpen(true);
        setSelectedDate(yesterdayDate);
    };

    const handleSnackbarClose = () => {
        setSnackbarMessage(false);
    };

    const onSuccess = async (public_token, metadata) => {
        const { token } = store.getState().auth;
        try {
            dispatch(
                setAccessTokenRequest({
                    publicToken: public_token,
                    token,
                    fetchBankAccounts,
                    companyId: companyData?.id,
                    setIsLoading,
                }),
            );
        } catch (error) {
            console.error('Error setting access token:', error);
        }
    };

    const { open, ready } = usePlaidLink({
        token: linkToken2,
        onSuccess,
    });

    const createPlaidLinkToken = (dateIn) => {
        const start_date = dayjs(dateIn).format('YYYY-MM-DD');
        const token = store.getState().auth.token;
        setIsCreateLinkLoading(true);
        dispatch(
            fetchLinkTokenRequest({
                token,
                start_date,
                handleAddDateModalClose,
                setIsCreateLinkLoading,
                setLinkToken2,
            }),
        );
    };

    const sync = () => {
        dispatch(clearBankTransactions());
        setSelectedBank(null);
        setBankAccountsLoading(true);
        const token = store.getState().auth.token;
        if (accounts?.length) {
            console.log('first');
            setIsLoading(true);
            dispatch(
                syncTransactionsRequest({
                    token,
                    fetchBankAccounts,
                    setIsLoading,
                }),
            );
        } else {
            console.log('error');
            setAccountsErrorMsg(`There's no accounts for sync transactions`);
            setAccountsError(true);
        }
    };

    const handleDateChange = (newDate) => {
        if (newDate && dayjs(newDate).isValid()) {
            setSelectedDate(dayjs(newDate));
        } else {
            setSelectedDate(null);
        }
    };

    const fetchBankAccounts = async () => {
        const { token } = store.getState().auth;
        try {
            const bankAccountsData = await getBankAccounts(
                companyData?.id,
                token,
            );
            dispatch(fetchBankAccountsSuccess(bankAccountsData));
            if (bankAccountsData && bankAccountsData?.length) {
                setSelectedBank(bankAccountsData[0]);
            }
        } catch (error) {
            console.error(error.message);
            dispatch(fetchBankAccountsFailure(error.message));
        } finally {
            setBankAccountsLoading(false);
        }
    };

    useEffect(() => {
        if (ready) {
            open();
        }
    }, [ready, open]);

    useEffect(() => {
        return () => {
            dispatch(clearBankTransactions());
        };
    }, []);

    useEffect(() => {
        if (companyData?.id) {
            fetchBankAccounts();
        }
    }, [companyData]);

    return (
        <Fragment>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: isMobile ? 0 : 4,
                    flexDirection: isMobile ? 'column' : 'row',
                    width: '100%',
                }}
            >
                <Typography
                    ml={isMobile ? 1.8 : 0}
                    pb={2}
                    sx={{ fontWeight: '800', fontSize: '20px' }}
                >
                    List of bank accounts
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        gap: isMobile ? '10px' : 0,
                    }}
                >
                    <Button
                        sx={{
                            border: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '1px solid #394150'
                                    : '1px solid #E5E6EB',
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#9EA3AE'
                                    : '#6C727F',
                            borderRadius: '8px',
                            outline: 'none',
                            textTransform: 'none',
                            marginRight: '5px',
                            paddingX: '20px',
                        }}
                        onClick={handleAddDateModalOpen}
                        startIcon={
                            <AccountBalanceOutlinedIcon
                                sx={classes.iconStyle}
                            />
                        }
                    >
                        Add a bank account
                    </Button>
                    <Button
                        startIcon={<SyncIcon />}
                        sx={{
                            backgroundColor: '#0061DB',
                            color: 'white',
                            textTransform: 'none',
                            borderRadius: '8px',
                            paddingX: '20px',
                            '&:hover': {
                                backgroundColor: '#0056b3',
                                '@media (hover: hover)': {
                                    backgroundColor: '#0056b3',
                                },
                            },
                        }}
                        onClick={sync}
                    >
                        Sync All
                    </Button>
                </Box>
            </Box>

            {bankAccountsLoading ? (
                <Box sx={classes.loader}>
                    <CircularProgress />
                </Box>
            ) : accounts && accounts?.length ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <BankCardCarousel
                        bankCardsData={accounts}
                        selectedBank={selectedBank}
                        setSelectedBank={setSelectedBank}
                    />

                    <BankTransactionsStack selectedBank={selectedBank} />
                </Box>
            ) : (
                <></>
            )}
            {accountsError && (
                <ErrorAnimation
                    onClose={() => setAccountsError(false)}
                    data={accountsErrorMsg}
                />
            )}
            {isLoading && <Animation />}
            <Snackbar
                open={snackbarMessage}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <AddBankStartDateModal
                handleDateChange={handleDateChange}
                open={isAddDateModalOpen}
                onClose={handleAddDateModalClose}
                selectedDate={selectedDate}
                createPlaidLinkToken={createPlaidLinkToken}
                isCreateLinkLoading={isCreateLinkLoading}
            />
        </Fragment>
    );
};

export default SyncBankAccounts;
