import { useCallback } from 'react';

import { useAxios } from '../../hooks/useAxios';

export const useInventoryService = () => {
    const { get, post, isLoading } = useAxios();

    const getCompanyInventory = useCallback(
        (companyId, page) =>
            get(`/inventory/${companyId}/inventory/?page=${page}`),
        [get],
    );

    const addFileInventory = ({ data, companyId }) =>
        post(`inventory/add-file-inventory/`, { data, companyId }, '');

    return {
        isLoading,
        addFileInventory,
        getCompanyInventory,
    };
};
