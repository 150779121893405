/* eslint-disable import/no-anonymous-default-export */
// services/bankService.js
import axios from 'axios';
import { CIRCLER_API_URL } from 'utils/constants';
import { store } from 'store';
const API_URL = `${CIRCLER_API_URL}/bank/accounts/list`;
const LINK_TOKEN_URL = `${CIRCLER_API_URL}/bank/create_link_token`;
const SET_ACCESS_TOKEN_URL = `${CIRCLER_API_URL}/bank/set_access_token`;
const IMPORT_TRANSACTIONS_URL = `${CIRCLER_API_URL}/bank/transactions/import`;
const UNIMPORT_TRANSACTIONS_URL = `${CIRCLER_API_URL}/bank/transactions/unimport`;
// const SYNC_INITIAL_TRANSACTIONS_URL = `${CIRCLER_API_URL}/bank/transactions/sync/initial`;
const SYNC_INITIAL_TRANSACTIONS_URL = `${CIRCLER_API_URL}/bank/transactions/sync`;
const BANK_TRANSACTIONS_URL = `${CIRCLER_API_URL}/bank/transactions/`;
const ACCOUNT_STATEMENT_URL = `${CIRCLER_API_URL}/bank/transactions/reconciliation/get_bank_statements`;
const ACCOUNT_STATEMENTS_URL = `${CIRCLER_API_URL}/bank/transactions/reconciliation/get_bank_statements`;
const DOWNLOAD_ACCOUNT_STATEMENTS_URL = (id) =>
    `${CIRCLER_API_URL}/bank/transactions/reconciliation/download_bank_statements/${id}/`;
const GET_BANK_RECONCILIATIONS = `${CIRCLER_API_URL}/bank/transactions/reconciliation/get_bank_reconciliations`;
const MATCHED_TRANSACTIONS_URL = `${CIRCLER_API_URL}/bank/transactions/reconciliation/create_match`;
const UPDATE_TRANSACTIONS_URL = `${CIRCLER_API_URL}/bank/transactions/update`;
const SYNC_TRANSACTIONS_URL = `${CIRCLER_API_URL}/bank/transactions/sync`;
export const COMPANY_FETCH = `${CIRCLER_API_URL}/company/user/`;
export const UPDATE_COMPANY = `${CIRCLER_API_URL}/bank/transactions/update_company`;
export const UPDATE_COA = `${CIRCLER_API_URL}/bank/transactions/update_coa`;
export const COA_FETCH = `${CIRCLER_API_URL}/core/customer_chart_of_accounts`;
export const SAVE_TRANSACTION = `${CIRCLER_API_URL}/bank/transactions/reconciliation/save_bank_reconciliations`;
export const COA_FETCH_WITH_CATEGORY = (compandyId) =>
    `${CIRCLER_API_URL}/core/customer_chart_of_accounts/${compandyId}/active/with-category`;
export const COA_ACCOUNT_UPDATE = (plaidId, accountId) =>
    `${CIRCLER_API_URL}/bank/transactions/update_coa/${plaidId}/${accountId}`;
export const BREAK_MATCH = `${CIRCLER_API_URL}/bank/transactions/reconciliation/break_match`;
export const SYNC_ALL_FETCH = `${CIRCLER_API_URL}/bank/transactions/fetch-next-page`;
export const BANK_FETCH_NEXT = `${CIRCLER_API_URL}/bank/transactions/?account_id`;
const MATCHED_TRANSACTION_URL = `${CIRCLER_API_URL}/bank/transactions/matched-transaction/`;
const UNMATCH_TRANSACTION_URL = `${CIRCLER_API_URL}/bank/transactions/unmatch/`;

export const getBankAccounts = async (companyId, token) => {
    try {
        const response = await axios.get(`${API_URL}?company_id=${companyId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const generateLinkToken = async (token, start_date) => {
    try {
        const response = await axios.post(
            LINK_TOKEN_URL,
            { ...(start_date && { start_date }) },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        return response.data.link_token;
    } catch (error) {
        throw error;
    }
};

export const setAccessToken = async (publicToken, token) => {
    try {
        const response = await axios.post(
            SET_ACCESS_TOKEN_URL,
            { public_token: publicToken },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchTransactions = async (account, page, token) => {
    try {
        const response = await axios.get(
            `${CIRCLER_API_URL}/bank/transactions/fetch-next-page/${account}/?page=${page}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const importTransactions = async (token, companyId, transactionIds) => {
    try {
        const response = await axios.post(
            IMPORT_TRANSACTIONS_URL,
            {
                company_id: companyId,
                transaction_ids: transactionIds,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        return response;
    } catch (error) {
        throw error;
    }
};

export const updateTransactions = async (token, data) => {
    try {
        const response = await axios.put(UPDATE_TRANSACTIONS_URL, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const unimportTransactions = async (
    token,
    companyId,
    transactionIds,
) => {
    try {
        const response = await axios.post(
            UNIMPORT_TRANSACTIONS_URL,
            {
                company_id: companyId,
                transaction_ids: transactionIds,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        return response;
    } catch (error) {
        throw error;
    }
};

export const syncInitialTransactions = async (
    formattedDate,
    plaidId,
    token,
) => {
    try {
        const response = await axios.post(
            SYNC_INITIAL_TRANSACTIONS_URL,
            {
                // start_date: formattedDate,
                plaid_item_id: plaidId,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

export const fetchBankTransactions = async (accountId, page, token, status) => {
    try {
        const response = await axios.get(
            `${BANK_TRANSACTIONS_URL}?account_id=${accountId}&page=${page}&status=${status}&page_size=10`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    accept: 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchBankReconciliations = async (accountId, token) => {
    try {
        const response = await axios.get(
            `${GET_BANK_RECONCILIATIONS}/${accountId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    accept: 'application/json',
                },
            },
        );
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const fetchAccountStatement = async (accountId, token) => {
    try {
        const response = await axios.get(
            `${ACCOUNT_STATEMENT_URL}/${accountId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    accept: 'application/json',
                },
            },
        );
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const fetchAccountStatements = async (token) => {
    try {
        const response = await axios.get(`${ACCOUNT_STATEMENTS_URL}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                accept: 'application/json',
            },
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const downloadAccountStatements = async (token, id) => {
    try {
        const response = await axios.post(
            `${DOWNLOAD_ACCOUNT_STATEMENTS_URL}/${id}/`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    accept: 'application/json',
                },
            },
        );
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const attachTransaction = async (plaidTransaction, data, token) => {
    try {
        const formData = new FormData();
        formData.append('plaid_transaction', plaidTransaction);
        if (data.type === 'text') {
            formData.append('text', data.inputText);
        }
        if (data.type === 'file') {
            formData.append('file', data.file);
        }

        const response = await axios.post(
            `${CIRCLER_API_URL}/bank/transactions/attach`,
            formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchUnmatchedTransactions = async (
    reconciliationId,
    page,
    pageSize,
    token,
) => {
    try {
        const response = await axios.get(
            `${CIRCLER_API_URL}/bank/transactions/reconciliation/get_unmatched`,
            {
                params: {
                    bank_reconciliation_id: reconciliationId,
                    page: page,
                    page_size: pageSize,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                    accept: 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchMatchedTransactions = async (
    reconciliationId,
    page,
    pageSize,
    token,
) => {
    try {
        const response = await axios.get(
            `${CIRCLER_API_URL}/bank/transactions/reconciliation/get_matched`,
            {
                params: {
                    bank_reconciliation_id: reconciliationId,
                    page: page,
                    page_size: pageSize,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                    accept: 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const matchTransactions = async (
    plaid_transaction_id,
    transaction_id,
    token,
) => {
    try {
        const response = await axios.post(
            MATCHED_TRANSACTIONS_URL,
            {
                plaid_transaction_id: plaid_transaction_id,
                transaction_id: transaction_id,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const inputEndingBalance = async (
    bankAccountId,
    endingBalance,
    beginningBalance,
    statementDate,
    token,
) => {
    try {
        const response = await axios.post(
            `${CIRCLER_API_URL}/bank/transactions/reconciliation/input_ending_balance`,
            {
                bank_account_id: bankAccountId,
                ending_balance: endingBalance,
                beginning_balance: beginningBalance,
                statement_date: statementDate,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchReconciliationTotals = async (
    bankAccountId,
    companyId,
    token,
) => {
    try {
        const response = await axios.get(
            `${CIRCLER_API_URL}/bank/transactions/reconciliation/get_reconciliation_totals/${bankAccountId}/${companyId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    accept: 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const syncTransactions = async (token) => {
    // Add this function
    try {
        const response = await axios.post(
            SYNC_TRANSACTIONS_URL,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        console.error('Error syncing transactions:', error?.response?.data);
        throw error;
    }
};

export const saveReconciliation = async (bank_reconciliation_id) => {
    const { token } = store.getState().auth;
    const response = await axios.post(
        `${SAVE_TRANSACTION}`,
        { bank_reconciliation_id },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        },
    );
    return response.data;
};

export const getMatchedTransaction = async ({
    companyId,
    plaidTransactionId,
}) => {
    const { token } = store.getState().auth;
    const response = await axios.get(
        `${MATCHED_TRANSACTION_URL}?company_id=${companyId}&plaid_transaction_id=${plaidTransactionId}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                accept: 'application/json',
            },
        },
    );
    return response.data;
};

export const unmatchTransaction = async ({ companyId, plaidTransactionId }) => {
    const { token } = store.getState().auth;
    const response = await axios.put(
        `${UNMATCH_TRANSACTION_URL}`,
        { company_id: companyId, plaid_transaction_id: plaidTransactionId },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        },
    );
    return response.data;
};

export default {
    getBankAccounts,
    generateLinkToken,
    setAccessToken,
    fetchTransactions,
    importTransactions,
    syncInitialTransactions,
    fetchBankTransactions,
    attachTransaction,
    fetchUnmatchedTransactions,
    fetchMatchedTransactions,
    matchTransactions,
    inputEndingBalance,
    fetchReconciliationTotals,
    syncTransactions,
    saveReconciliation,
    unimportTransactions,
    getMatchedTransaction,
};
