import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useMediaQuery } from '@mui/material';

import { useThemeToggle } from '../hooks/useThemeToggle';
import { useCompanyService } from '../store/services/company.service';
import { UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_ERROR } from '../store/actions';

const Subscriptions = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { themeMode } = useThemeToggle();
    const { createStripeSession } = useCompanyService();
    const isDarkMode = themeMode === 'dark' ? true : false;
    const { getUserAssociatedCompany } = useCompanyService();
    const publishableKey = process.env.REACT_APP_STRIPE_INTENT;
    const isScreenLowerThan1024 = useMediaQuery('(max-width: 1025px)');
    const pricingTableId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;

    const companyData = useSelector((state) => state.company.data);
    const companyid = useSelector((state) => state.company.data?.id);
    const customerEmail = useSelector((state) => state.me.data.email);

    useEffect(() => {
        if (companyData && companyData?.stripeStatus === 'active') {
            navigate('/dashboard');
        }
    }, [companyData]);

    const handleCreateStripeSession = useCallback(async () => {
        const response = await createStripeSession(companyid);

        if (response.url) {
            window.location.href = response.url;
        }
    }, [companyid, createStripeSession]);

    const navigateToHome = useCallback(() => {
        navigate('/home');
    }, [navigate]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (!companyid) {
            getUserAssociatedCompany()
                .then((data) =>
                    dispatch(
                        UPDATE_COMPANY_SUCCESS({
                            data: {
                                id: data[0].id,
                                name: data[0].name,
                                timezone: {
                                    label: data[0].timezone,
                                    value: data[0].timezone,
                                },
                                industry: {
                                    label: data[0].industry_name,
                                    value: data[0].industry,
                                },
                                entity: {
                                    label: data[0].entity,
                                    value: data[0].entity,
                                },
                                address: data[0].address,
                                email: data[0].email,
                                stripeTrialExpiry: data[0].stripe_trial_expiry,
                                subscription_active:
                                    data[0].subscription_active,
                                stripeStatus: data[0].stripe_status,
                            },
                        }),
                    ),
                )
                .catch((err) => {
                    dispatch(UPDATE_COMPANY_ERROR({ err }));
                });
        }
    });

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}
            >
                <button
                    onClick={navigateToHome}
                    style={{
                        color: 'grey',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        fontSize: '16px',
                        marginBottom: '10px',
                        marginLeft: '30px',
                    }}
                >
                    Home Screen
                </button>
                <button
                    onClick={handleCreateStripeSession}
                    style={{
                        color: 'grey',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        fontSize: '16px',
                        marginBottom: '30px',
                        marginLeft: '30px',
                    }}
                >
                    Manage Billing
                </button>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    margin: '5px 25px',
                }}
            >
                <p
                    style={{
                        fontWeight: '700px',
                        fontSize: isScreenLowerThan1024 ? '20px' : '24px',
                        color: isDarkMode ? '#F9FAFB' : '#121826',
                    }}
                >
                    {companyData?.stripeStatus === 'trialing'
                        ? 'Upgrade now to enjoy uninterrupted access! Pay only after your trial ends.'
                        : 'Your trial has ended. Upgrade today to continue enjoying key benefits'}
                </p>
            </div>

            <stripe-pricing-table
                pricing-table-id={pricingTableId}
                publishable-key={publishableKey}
                client-reference-id={companyid}
                customer-email={customerEmail}
            ></stripe-pricing-table>
        </div>
    );
};

export default Subscriptions;
