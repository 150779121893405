import { useCallback } from 'react';

import { useAxios } from '../../hooks/useAxios';

export const useBookkeeperService = () => {
    const { get, post, isLoading } = useAxios();

    const addManualJournalEntry = (data) =>
        post(`bookkeeper/manual-journal-entry/`, data, '');

    const getManualJournalEntry = useCallback(
        (companyId, page) =>
            get(`bookkeeper/manual-journal-entry/${companyId}/?page=${page}`),
        [get],
    );

    return {
        isLoading,
        addManualJournalEntry,
        getManualJournalEntry,
    };
};
