import { store } from 'store';
import { useSelector } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';

import {
    Box,
    Radio,
    Alert,
    Snackbar,
    IconButton,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {
    updateTransactions,
    unmatchTransaction,
    getMatchedTransaction,
} from 'store/services/bank.Service';
import { CircleLoading } from 'shared';
import TransferForm from './TransferForm';
import CategorizeForm from './CategorizeForm';
import { rowAccordionStyles } from '../../style/style';
import CreditCardPaymentForm from './CreditCardPaymentForm';
import { formatAccountCategoriesData } from 'utils/helpers';
import { COA_FETCH_WITH_CATEGORY } from 'store/services/bank.Service';

const radioGroupOptions = [
    { value: 'categorize', label: 'Categorize' },
    { value: 'transfer', label: 'Transfer' },
    { value: 'creditCardPayment', label: 'Credit Card Payment' },
];

const RowAccordion = ({ rowData, onClose, handleBankTransactions }) => {
    const classes = rowAccordionStyles();
    const token = useSelector((state) => state.auth.token);
    const { id: companyId } = useSelector((state) => state.company.data);

    const [alertData, setAlertData] = useState({
        severity: '',
        open: false,
        message: '',
    });
    const [coaCategoryData, setCoaCategoryData] = useState({
        title: 'Select',
        children: [],
        loading: true,
    });
    const [selectedOption, setSelectedOption] = useState(
        rowData?.transaction_type == 'transaction'
            ? 'categorize'
            : rowData?.transaction_type == 'transfer'
            ? 'transfer'
            : 'creditCardPayment',
    );
    const [matchedTransaction, setMatchedTransaction] = useState(null);

    const handleUpdateTransaction = async (data) => {
        try {
            await updateTransactions(token, {
                plaid_transaction_id: rowData.id,
                coa_account_id: data.coaAccountId,
                merchant_name: data.merchantName,
                transaction_type:
                    selectedOption == 'categorize'
                        ? 'transaction'
                        : selectedOption == 'transfer'
                        ? 'transfer'
                        : 'credit_card_payment',
            });
            handleBankTransactions();
        } catch (error) {
            handleErrorAlert();
        }
    };

    const handleErrorAlert = () => {
        setAlertData({
            severity: 'error',
            message: 'Something went wrong, please try again',
            open: true,
        });
    };

    const handleGetMatchedTransaction = async () => {
        try {
            const response = await getMatchedTransaction({
                plaidTransactionId: rowData?.id,
                companyId,
            });
            setMatchedTransaction(response.data);
        } catch (error) {
            handleErrorAlert();
        }
    };

    const handleUnmatchTransaction = async () => {
        try {
            const response = await unmatchTransaction({
                plaidTransactionId: rowData?.id,
                companyId,
            });
            setAlertData({
                severity: 'success',
                message: response.message,
                open: true,
            });
            handleBankTransactions();
        } catch (error) {
            handleErrorAlert();
        }
    };

    const fetchCompanyAccountsCategoryData = async () => {
        setCoaCategoryData({
            title: 'Select',
            children: [],
            loading: true,
        });
        try {
            const { token } = store.getState().auth;

            const response = await fetch(COA_FETCH_WITH_CATEGORY(companyId), {
                method: 'GET',
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setCoaCategoryData(
                formatAccountCategoriesData({ ...data, loading: false }),
            );
        } catch (error) {
            console.error('Error fetching dropdown options:', error);
            setCoaCategoryData({
                title: 'Select',
                children: [],
                loading: false,
            });
        }
    };

    const handleAlertClose = () => {
        setAlertData((prevState) => ({
            ...prevState,
            open: false,
        }));
    };

    useEffect(() => {
        fetchCompanyAccountsCategoryData();
        if (rowData?.status === 'matched') {
            handleGetMatchedTransaction();
        }
    }, []);

    return (
        <Box sx={classes.root}>
            {coaCategoryData.loading ||
            (rowData?.status === 'matched' && !matchedTransaction) ? (
                <CircleLoading />
            ) : (
                <Fragment>
                    <Box sx={classes.radioButtonsWrapper}>
                        <RadioGroup
                            row
                            value={selectedOption}
                            onChange={(event) =>
                                setSelectedOption(event.target.value)
                            }
                            sx={classes.radioGroup}
                        >
                            {radioGroupOptions.map((option) => (
                                <FormControlLabel
                                    sx={classes.radioButtonLabel}
                                    value={option.value}
                                    control={
                                        <Radio sx={classes.radioButtonIcon} />
                                    }
                                    label={option.label}
                                />
                            ))}
                        </RadioGroup>
                        <IconButton sx={classes.crossIcon} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        {selectedOption === 'categorize' && (
                            <CategorizeForm
                                data={rowData}
                                coaCategoryData={coaCategoryData}
                                handleUpdateTransaction={
                                    handleUpdateTransaction
                                }
                            />
                        )}
                        {selectedOption === 'transfer' && (
                            <TransferForm
                                data={rowData}
                                coaCategoryData={coaCategoryData}
                                matchedTransaction={matchedTransaction}
                                handleUpdateTransaction={
                                    handleUpdateTransaction
                                }
                                handleUnmatchTransaction={
                                    handleUnmatchTransaction
                                }
                            />
                        )}
                        {selectedOption === 'creditCardPayment' && (
                            <CreditCardPaymentForm
                                data={rowData}
                                coaCategoryData={coaCategoryData}
                                matchedTransaction={matchedTransaction}
                                handleUpdateTransaction={
                                    handleUpdateTransaction
                                }
                                handleUnmatchTransaction={
                                    handleUnmatchTransaction
                                }
                            />
                        )}
                    </Box>
                </Fragment>
            )}

            <Snackbar
                open={alertData.open}
                autoHideDuration={5000}
                onClose={handleAlertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertData.severity}
                    sx={{ width: '100%' }}
                >
                    {alertData.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default RowAccordion;
