export const journalTableHeaders = [
    {
        id: 'name',
        label: 'Name',
        sort: false,
        verticalAlign: 'top',
    },
    {
        id: 'date',
        label: 'Date',
        sort: false,
        verticalAlign: 'top',
    },
    {
        id: 'amount',
        label: 'Total',
        sort: false,
        verticalAlign: 'top',
        render: (row) => `${row.currency} ${row.total_amount}`,
    },
];
