import { Navigate } from 'react-router-dom';

import {
    Home,
    Login,
    Account,
    History,
    Dashboard,
    Transaction,
    Profile,
} from '../components';
import Journal from 'components/Journal';
import Invoice from '../components/Invoice';
import { SignUp } from '../components/Signup';
import Onboarding from '../components/Onboarding';
import Subscriptions from '../components/Subscriptions';
import ForgotPassword from '../components/ForgotPassword';
import InvoiceList from '../components/Invoice/InvoiceList';
import InvoiceSetting from '../components/Invoice/Settings';
import { ProtectedRoute } from '../components/ProtectedRoute';
import ConfirmNewPassword from '../components/ConfirmNewPassword';
import PreviewInvoice from 'components/Invoice/Components/InvoicePreview/PreviewInvoice';
import Inventory from 'components/Inventory';

export const PRIVATE_ROUTES = [
    {
        path: '/onboarding',
        element: (
            <ProtectedRoute route="onboarding">
                <Onboarding />
            </ProtectedRoute>
        ),
        label: 'onboarding',
    },
    {
        path: '/home',
        element: (
            <ProtectedRoute>
                <Home />
            </ProtectedRoute>
        ),
        label: 'Home',
    },
    {
        path: '/inventory',
        element: (
            <ProtectedRoute>
                <Inventory />
            </ProtectedRoute>
        ),
        label: 'Inventory',
    },
    {
        path: '/',
        element: <Navigate replace to="/dashboard" />,
        label: 'Redirect to Home',
    },
    {
        path: '/dashboard',
        element: (
            <ProtectedRoute>
                <Dashboard />
            </ProtectedRoute>
        ),
        label: 'Dashboard',
    },
    {
        path: '/account',
        element: (
            <ProtectedRoute>
                <Account />
            </ProtectedRoute>
        ),
        label: 'Chart of Accounts',
    },
    {
        path: '/transaction',
        element: (
            <ProtectedRoute>
                <Transaction label="Transactions" />
            </ProtectedRoute>
        ),
        label: 'Transactions',
    },
    {
        path: '/import-bank-transactions',
        element: (
            <ProtectedRoute>
                <Transaction label="Import Bank Transactions" />
            </ProtectedRoute>
        ),
        label: 'Import Bank Transactions',
    },
    {
        path: '/scan-receipt',
        element: (
            <ProtectedRoute>
                <Transaction label="Scan Receipt" />
            </ProtectedRoute>
        ),
        label: 'Scan Receipt',
    },
    {
        path: '/bank-reconciliation',
        element: (
            <ProtectedRoute>
                <Transaction label="Bank Reconciliation" />
            </ProtectedRoute>
        ),
        label: 'Bank Reconciliation',
    },
    {
        path: '/inventory-reconciliation',
        element: (
            <ProtectedRoute>
                <Transaction label="Inventory Reconciliation" />
            </ProtectedRoute>
        ),
        label: 'Inventory Reconciliation',
    },
    {
        path: '/history',
        element: (
            <ProtectedRoute>
                <History />
            </ProtectedRoute>
        ),
        label: 'History',
    },
    {
        path: '/invoice',
        element: (
            <ProtectedRoute>
                <Invoice />
            </ProtectedRoute>
        ),
        label: 'Invoice',
    },
    {
        path: '/invoiceList',
        element: (
            <ProtectedRoute>
                <InvoiceList />
            </ProtectedRoute>
        ),
        label: 'Invoice',
    },
    {
        path: '/invoiceSettings',
        element: (
            <ProtectedRoute>
                <InvoiceSetting generalSettings={true} />
            </ProtectedRoute>
        ),
        label: 'Invoice',
    },
    {
        path: '/invoiceSetting',
        element: (
            <ProtectedRoute>
                <InvoiceSetting />
            </ProtectedRoute>
        ),
        label: 'Invoice',
    },
    {
        path: '/profile',
        element: (
            <ProtectedRoute>
                <Profile />
            </ProtectedRoute>
        ),
        label: 'Profile',
    },
    {
        path: '/subscriptions',
        element: (
            <ProtectedRoute>
                <Subscriptions />
            </ProtectedRoute>
        ),
        label: 'Subscriptions',
    },
    {
        path: '/journal-entries',
        element: (
            <ProtectedRoute>
                <Journal />
            </ProtectedRoute>
        ),
        label: 'Journal Entries',
    },
];

export const PUBLIC_ROUTES = [
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/signup',
        element: <SignUp />,
    },
    {
        path: '/forgotPassword',
        element: <ForgotPassword />,
    },
    {
        path: '/password-reset/:uid/:token',
        element: <ConfirmNewPassword />,
    },

    {
        path: '/invoice/:invoiceIds/:companyIds',
        element: <PreviewInvoice />,
    },
];
