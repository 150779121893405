import dayjs from 'dayjs';

import { Chip } from '@mui/material';

import { statusColors } from './statusColors';

export const inventoryTableHeaders = [
    {
        id: 'reference_id',
        label: 'Reference',
        sort: false,
    },
    {
        id: 'order_id',
        label: 'Order',
        sort: false,
    },
    {
        id: 'description',
        label: 'Description',
        sort: false,
    },
    {
        id: 'class_1',
        label: 'Class 1',
        sort: false,
    },
    {
        id: 'class_2',
        label: 'Class 2',
        sort: false,
    },
    {
        id: 'class_3',
        label: 'Class 3',
        sort: false,
    },
    {
        id: 'purchase_price',
        label: 'Purchase Price',
        sort: false,
    },
    {
        id: 'expires_at',
        label: 'Expires at',
        sort: false,
        render: (row) =>
            `${dayjs(new Date(row.expires_at)).format('YYYY-MM-DD')}`,
    },
    {
        id: 'purchased_at',
        label: 'Purchased At',
        sort: false,
        render: (row) =>
            `${dayjs(new Date(row.purchased_at)).format('YYYY-MM-DD')}`,
    },
    {
        id: 'status',
        label: 'Status',
        sort: false,
        render: (row) => (
            <Chip label={row.status} color={statusColors[row.status]} />
        ),
    },
];
