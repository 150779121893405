import { store } from 'store';
import { useDispatch } from 'react-redux';
import React, { useState, Fragment, useEffect } from 'react';

import { Grid, Stack, Button } from '@mui/material';

import Animation from './Animation';
import { bankTransactionsMakeStyles } from '../style/style';
import ImportTransactionsSelect from '../ImportTransactionsSelect';
import { fetchBankTransactionsRequest } from 'store/actions';

const tabsData = [{ label: 'Not imported' }, { label: 'Imported' }];

const BankTransactionsStack = ({ selectedBank }) => {
    const dispatch = useDispatch();
    const classes = bankTransactionsMakeStyles({});

    const { bankTransactions } = store.getState().bank;

    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState('Not imported');

    const handleBankTransactions = (tab) => {
        const tabLabel = tab || selectedTab;
        const { token } = store.getState().auth;

        setLoading(true);
        const result = {
            id: selectedBank?.id,
            name: selectedBank?.name,
        };
        dispatch(
            fetchBankTransactionsRequest({
                accountId: selectedBank.id,
                page: 1,
                token: token,
                onSyncAll: () => setLoading(false),
                result,
                status: tabLabel === 'Not imported' ? 'unimported' : 'imported',
            }),
        );
    };

    const changeTab = (tabLabel) => {
        setSelectedTab(tabLabel);
        handleBankTransactions(tabLabel);
    };

    const getTabButtonStyle = (tabLabel) => ({
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: selectedTab === tabLabel ? '600' : '500',
        backgroundColor: selectedTab === tabLabel ? '#CEEFDF' : 'transparent',
        borderRadius: selectedTab === tabLabel ? '3px' : 'none',
        color: selectedTab === tabLabel ? '#0A8C4D' : '#6C727F',
    });

    useEffect(() => {
        if (selectedBank?.id) {
            handleBankTransactions(selectedTab);
        }
    }, [selectedBank]);

    return (
        <Fragment>
            <Stack
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                gap={1}
                width={{ xs: '100%', md: 'fit-content' }}
                sx={classes.tabsStack}
                alignItems="center"
            >
                {tabsData?.map((tab) => (
                    <Grid
                        item
                        key={tab.label}
                        style={{
                            borderRadius: '5px',
                        }}
                    >
                        <Button
                            onClick={() => changeTab(tab.label)}
                            style={getTabButtonStyle(tab.label)}
                            variant={
                                selectedTab === tab.label ? 'contained' : 'text'
                            }
                        >
                            {tab.label}
                        </Button>
                    </Grid>
                ))}
            </Stack>
            {loading || !bankTransactions ? (
                <Animation />
            ) : (
                <ImportTransactionsSelect
                    setIsLoading={setLoading}
                    accounts={[
                        { id: selectedBank.id, name: selectedBank.name },
                    ]}
                    accounts2={[selectedBank]}
                    bankTransactionsTabsInfo={true}
                    selectedTab={selectedTab}
                    handleBankTransactions={handleBankTransactions}
                />
            )}
        </Fragment>
    );
};

export default BankTransactionsStack;
