import React, { useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Grid, Typography, Box } from '@mui/material';

import { matchTransactionInfoStyles } from '../../style/style';

const MatchedTransactionInfo = ({ transaction, handleUnmatchTransaction }) => {
    const {
        date,
        amount,
        category,
        merchant_name,
        currency_code,
        category_detailed,
    } = transaction;

    const classes = matchTransactionInfoStyles();
    const [unmatchLoading, setUnmatchLoading] = useState(false);

    const handleUnmatchClick = () => {
        setUnmatchLoading(true);
        handleUnmatchTransaction();
    };

    return (
        <Box sx={classes.rootWrapper}>
            <Grid container spacing={2} alignItems="center">
                <Grid
                    item
                    xs={12}
                    sm={4}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                >
                    <Typography variant="h6">
                        {currency_code} {amount}
                    </Typography>
                    <Typography variant="body2">{merchant_name}</Typography>
                </Grid>

                <Grid item xs={12} sm={5}>
                    <Typography variant="body2">
                        {`${date} - ${category_detailed.replace(/_/g, ' ')}`}
                    </Typography>
                    <Typography variant="body2" sx={classes.category}>
                        {category}
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={3}
                    display="flex"
                    justifyContent="flex-end"
                >
                    <LoadingButton
                        size="small"
                        variant="contained"
                        loading={unmatchLoading}
                        sx={classes.unmatchButton}
                        onClick={handleUnmatchClick}
                    >
                        Unmatch
                    </LoadingButton>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MatchedTransactionInfo;
