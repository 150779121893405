export const makeStyles = ({ isScreenLowerThan1024 }) => ({
    root: {
        p: isScreenLowerThan1024 ? '20px 20px' : '40px 40px',
        display: 'flex',
        flexDirection: 'column',
        gap: isScreenLowerThan1024 ? '16px' : '32px',
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '300px',
    },
});
