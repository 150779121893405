import React, { Fragment } from 'react';

import { Grid } from '@mui/material';
import { DataTable, TablePagination } from 'shared';

import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { journalTableHeaders } from '../utils/journalTableHeaders';

const JournalTable = ({ page, handlePageChange, loading, data }) => {
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');

    return (
        <Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <DataTable
                        headCells={journalTableHeaders}
                        isLoading={loading}
                        data={data.results}
                    ></DataTable>
                </Grid>
                <Grid item xs={12} p={'10px 0'}>
                    <TablePagination
                        page={page}
                        count={data.count}
                        isMobileScreen={isMobileScreen}
                        onChange={(e, pageNumber) =>
                            handlePageChange(pageNumber)
                        }
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default JournalTable;
