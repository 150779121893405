import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

import { Box, CircularProgress } from '@mui/material';

import { makeStyles } from './style/style';
import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { useInventoryService } from 'store/services/inventory.service';
import InventoryTable from './components/InventoryTable';

const Inventory = () => {
    const companyData = useSelector((state) => state.company.data);
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');
    const { getCompanyInventory, isLoading } = useInventoryService();
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');

    const classes = makeStyles({});

    const [page, setPage] = useState(1);
    const [inventoryData, setInventoryData] = useState([]);

    const handleGetInventory = async (page) => {
        const response = await getCompanyInventory(companyData.id, page);
        console.log(response);
        setInventoryData(response);
    };

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
        handleGetInventory(pageNumber);
    };

    useEffect(() => {
        if (companyData) {
            handleGetInventory(page);
        }
    }, [companyData]);

    return (
        <Box sx={classes.root}>
            {companyData ? (
                <Box>
                    <InventoryTable
                        page={page}
                        loading={isLoading}
                        data={inventoryData}
                        handlePageChange={handlePageChange}
                    />
                </Box>
            ) : (
                <Box sx={classes.loader}>
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};

export default Inventory;
