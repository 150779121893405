import { ReactComponent as Invoice } from '../assets/icons/invoice.svg';
import { ReactComponent as InvoiceSelected } from '../assets/icons/InvoiceSelected.svg';
import { ReactComponent as InvoiceSelectedDarkMode } from '../assets/icons/InvoiceSelectedDarkMode.svg';
import { ReactComponent as Home } from '../assets/icons/Home.svg';
import { ReactComponent as HomeSelected } from '../assets/icons/HomeSelected.svg';
import { ReactComponent as HomeSelectedDarkMode } from '../assets/icons/HomeSelectedDarkMode.svg';
import { ReactComponent as History } from '../assets/icons/History.svg';
import { ReactComponent as HistorySelected } from '../assets/icons/HistorySelected.svg';
import { ReactComponent as HistorySelectedDarkMode } from '../assets/icons/HistorySelectedDarkMode.svg';
import { ReactComponent as Account } from '../assets/icons/Account.svg';
import { ReactComponent as AccountSelected } from '../assets/icons/AccountSelected.svg';
import { ReactComponent as AccountSelectedDarkMode } from '../assets/icons/AccountSelectedDarkMode.svg';
import { ReactComponent as Dashboard } from '../assets/icons/Dashboard.svg';
import { ReactComponent as DashboardSelected } from '../assets/icons/DashboardSelected.svg';
import { ReactComponent as DashboardSelectedDarkMode } from '../assets/icons/DashboardSelectedDarkMode.svg';
import { ReactComponent as Transaction } from '../assets/icons/Transaction.svg';
import { ReactComponent as TransactionSelected } from '../assets/icons/TransactionSelected.svg';
import { ReactComponent as TransactionSelectedDarkMode } from '../assets/icons/TransactionSelectedDarkMode.svg';
import { ReactComponent as Logout } from '../assets/icons/Logout.svg';
import { ReactComponent as MyProfileSelected } from '../assets/icons/UserProfileSelected.svg';
import { ReactComponent as DarkModeSelected } from '../assets/icons/DarkModeSelected.svg';
import { ReactComponent as DarkMode } from '../assets/icons/DarkMode.svg';
import { ReactComponent as UserProfile } from '../assets/icons/UserProfile.svg';
import { ReactComponent as JournalEntries } from '../assets/icons/JournalEntries.svg';
import { ReactComponent as JournalEntriesSelected } from '../assets/icons/JournalEntriesSelected.svg';
import { ReactComponent as JournalEntriesDarkMode } from '../assets/icons/JournalEntriesDarkMode.svg';

export const SIDE_MENU_OPTIONS = [
    {
        name: 'Dashboard',
        icon: Dashboard,
        selectedIcon: DashboardSelected,
        darkMode: DashboardSelectedDarkMode,
        path: '/dashboard',
        displayName: 'Dashboard',
    },
    {
        name: 'Transaction',
        icon: Transaction,
        selectedIcon: TransactionSelected,
        darkMode: TransactionSelectedDarkMode,
        path: '/transaction',
        displayName: 'Transaction',
        collapse: true,
        items: [
            {
                name: 'Transactions',
                path: '/transaction',
            },
            {
                name: 'Import bank transactions',
                path: '/import-bank-transactions',
            },
            {
                name: 'Scan Receipt',
                path: '/scan-receipt',
            },
            {
                name: 'Bank Reconciliation',
                path: '/bank-reconciliation',
            },
            {
                name: 'Inventory Reconciliation',
                path: '/inventory-reconciliation',
            },
        ],
    },
    {
        name: 'Account',
        icon: Account,
        selectedIcon: AccountSelected,
        darkMode: AccountSelectedDarkMode,
        path: '/account',
        displayName: 'Chart of Accounts',
    },
    {
        name: 'Invoice',
        icon: Invoice,
        selectedIcon: InvoiceSelectedDarkMode,
        darkMode: InvoiceSelected,
        path: '/invoiceList',
        displayName: 'Invoice',
    },
    {
        name: 'Journal Entries',
        icon: JournalEntries,
        selectedIcon: JournalEntriesSelected,
        darkMode: JournalEntriesDarkMode,
        path: '/journal-entries',
        displayName: 'Journal Entries',
    },
    {
        name: 'Inventory',
        icon: Home,
        selectedIcon: HomeSelected,
        darkMode: HomeSelectedDarkMode,
        path: '/inventory',
        displayName: 'Inventory',
    },
    // {
    //     name: 'History',
    //     icon: History,
    //     selectedIcon: HistorySelected,
    //     darkMode: HistorySelectedDarkMode,
    //     path: '/history',
    //     notificationCount: 0,
    //     displayName: 'History',
    // },
];

export const MOBILE_MENU_OPTIONS = [
    {
        name: 'Profile',
        icon: UserProfile,
        selectedIcon: MyProfileSelected,
        darkMode: UserProfile,
        path: '/profile',
        displayName: 'Profile',
    },
    {
        name: 'DarkMode',
        icon: DarkMode,
        darkMode: DarkMode,
        selectedIcon: DarkModeSelected,
        path: '/darkMode',
        showSwitch: true,
    },
    {
        name: 'Logout',
        icon: Logout,
        darkMode: Logout,
        selectedIcon: Logout,
        path: '/logout',
    },
    {
        name: 'Privacy Policy',
        path: 'https://www.termsfeed.com/live/c453ce2a-a7f6-4fd4-8956-efc53c6f2510',
    },
    {
        name: 'Terms and Conditions',
        path: 'https://www.termsfeed.com/live/2c8418f9-830b-4917-95c9-1a30f68a8c7a',
    },
    {
        name: 'About Us',
        path: 'https://circler.io/',
    },
];
