import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import {
    Box,
    Grid,
    Divider,
    Button,
    FormHelperText,
    CircularProgress,
} from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import InfoIcon from '@mui/icons-material/Info';
import LoadingButton from '@mui/lab/LoadingButton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import {
    makeStyles,
    errorMessage,
    descriptionText,
    customLabelStyle,
    customSelectStyle,
    customSelectLabelStyle,
} from '../style/style';
import TotalDisplay from './TotalDisplay';
import JournalEntryRow from './JournalEntryRow';
import { useThemeToggle } from 'hooks/useThemeToggle';
import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { useCoreService } from 'store/services/core.service';
import { Modal, Typography, TextField, Autocomplete } from 'shared';
import { useBookkeeperService } from 'store/services/bookkeeper.service';
import { journalValidationSchema } from '../utils/journalValidationSchema';

const initialValues = {
    date: null,
    name: '',
    invoiceNumber: '',
    currency: { value: 'USD', label: 'USD' },
    description: '',
    journalEntries: [
        { account: null, debit: '', credit: '' },
        { account: null, debit: '', credit: '' },
    ],
};

const JournalForm = ({
    type,
    open,
    companyId,
    handleCloseModal,
    handleJournalCreationSuccess,
}) => {
    const { themeMode } = useThemeToggle();
    const { addManualJournalEntry, isLoading } = useBookkeeperService();
    const { getCurrencyCodes, getCompanyChartOfAccountsList } =
        useCoreService();
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');
    const classes = makeStyles({ isScreenLowerThan1024 });

    const [loader, setLoader] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [currencies, setCurrencies] = useState([]);

    useEffect(() => {
        Promise.all([
            getCurrencyCodes(),
            getCompanyChartOfAccountsList(companyId),
        ])
            .then(([currencies, accounts]) => {
                setCurrencies(currencies);
                setAccounts(accounts);
                setLoader(false);
            })
            .catch(console.error);
    }, []);

    const handleSubmit = async (values) => {
        const totalDebit = values.journalEntries.reduce(
            (sum, entry) => sum + (Number(entry.debit) || 0),
            0,
        );
        const totalCredit = values.journalEntries.reduce(
            (sum, entry) => sum + (Number(entry.credit) || 0),
            0,
        );

        if (totalDebit !== totalCredit) return;

        const response = await addManualJournalEntry({
            ...values,
            totalAmount: totalCredit,
            company_id: companyId,
        });

        if (response.message === 'success') {
            handleJournalCreationSuccess();
        }
    };

    if (loader) {
        return (
            <Modal fullWidth open={open} title={`${type} Journal Entry`}>
                <Box sx={classes.loader}>
                    <CircularProgress />
                </Box>
            </Modal>
        );
    }

    return (
        <Modal
            fullWidth
            open={open}
            title={`${type} Journal Entry`}
            actionBtnTitle={
                <Typography text={type === 'Add' ? 'Create' : 'Update'} />
            }
            handleClose={handleCloseModal}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={journalValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue, errors, touched }) => (
                    <Form>
                        <Box width="100%" gap={2} mt={1} border="none">
                            <Grid container alignItems="center" spacing={2}>
                                {/* Basic Information Fields */}
                                <Grid item xs={12}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DatePicker
                                            value={
                                                values.date &&
                                                dayjs(values.date)
                                            }
                                            onChange={(date) =>
                                                setFieldValue('date', date)
                                            }
                                            sx={classes.datePicker}
                                            slotProps={{
                                                textField: {
                                                    error:
                                                        touched.date &&
                                                        !!errors.date,
                                                    helperText:
                                                        touched.date &&
                                                        errors.date,
                                                    FormHelperTextProps: {
                                                        sx: errorMessage,
                                                    },
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        name="name"
                                        placeholder="Name"
                                        value={values.name}
                                        onChange={(e) =>
                                            setFieldValue(
                                                'name',
                                                e.target.value,
                                            )
                                        }
                                        customLabelStyles={customLabelStyle}
                                        error={touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                    />
                                </Grid>

                                <Grid item xs={6} md={3}>
                                    <TextField
                                        fullWidth
                                        name="invoiceNumber"
                                        placeholder="Number"
                                        value={values.invoiceNumber}
                                        onChange={(e) =>
                                            setFieldValue(
                                                'invoiceNumber',
                                                e.target.value,
                                            )
                                        }
                                        customLabelStyles={customLabelStyle}
                                        error={
                                            touched.invoiceNumber &&
                                            !!errors.invoiceNumber
                                        }
                                        helperText={
                                            touched.invoiceNumber &&
                                            errors.invoiceNumber
                                        }
                                    />
                                </Grid>

                                <Grid item xs={6} md={3}>
                                    <Autocomplete
                                        options={currencies}
                                        labelKey="label"
                                        valueKey="value"
                                        placeholder="Currency"
                                        name="currency"
                                        value={values.currency}
                                        onChange={(e, value) =>
                                            setFieldValue('currency', value)
                                        }
                                        isSearchField={false}
                                        customLabelStyles={
                                            customSelectLabelStyle
                                        }
                                        customStyle={customSelectStyle}
                                        disableClearable
                                        error={!!errors.currency}
                                        helperText={errors.currency}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name="description"
                                        placeholder="Description"
                                        value={values.description}
                                        onChange={(e) =>
                                            setFieldValue(
                                                'description',
                                                e.target.value,
                                            )
                                        }
                                        customLabelStyles={customLabelStyle}
                                        multiline
                                        rows={3}
                                        InputProps={{
                                            style: descriptionText(themeMode),
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider
                                        sx={{
                                            background: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#394150'
                                                    : '#E5E6EB',
                                        }}
                                    />
                                </Grid>

                                {/* Journal Entries Section */}
                                <FieldArray name="journalEntries">
                                    {({ push, remove }) => (
                                        <>
                                            {values.journalEntries.map(
                                                (entry, index) => (
                                                    <JournalEntryRow
                                                        key={index}
                                                        index={index}
                                                        entry={entry}
                                                        setFieldValue={
                                                            setFieldValue
                                                        }
                                                        touched={touched}
                                                        errors={errors}
                                                        accounts={accounts}
                                                        onRemove={remove}
                                                        canRemove={
                                                            values
                                                                .journalEntries
                                                                .length > 2
                                                        }
                                                    />
                                                ),
                                            )}

                                            {/* Totals Display */}
                                            <Grid item xs={12} md={5} />
                                            <Grid item xs={5} md={3}>
                                                <TotalDisplay
                                                    currency={
                                                        values.currency?.value
                                                    }
                                                    entries={
                                                        values.journalEntries
                                                    }
                                                    type="debit"
                                                />
                                            </Grid>
                                            <Grid item xs={5} md={3}>
                                                <TotalDisplay
                                                    currency={
                                                        values.currency?.value
                                                    }
                                                    entries={
                                                        values.journalEntries
                                                    }
                                                    type="credit"
                                                />
                                            </Grid>

                                            {/* Error Message */}
                                            {values.journalEntries
                                                .reduce(
                                                    (sum, entry) =>
                                                        sum +
                                                        Number(
                                                            entry.credit || 0,
                                                        ),
                                                    0,
                                                )
                                                .toFixed(2) !==
                                                values.journalEntries
                                                    .reduce(
                                                        (sum, entry) =>
                                                            sum +
                                                            Number(
                                                                entry.debit ||
                                                                    0,
                                                            ),
                                                        0,
                                                    )
                                                    .toFixed(2) && (
                                                <Grid item xs={12}>
                                                    <Box
                                                        sx={
                                                            classes.buttonWrapper
                                                        }
                                                    >
                                                        <FormHelperText
                                                            error
                                                            sx={
                                                                classes.errorMessage
                                                            }
                                                        >
                                                            <InfoIcon
                                                                fontSize="14px"
                                                                sx={
                                                                    classes.infoIcon
                                                                }
                                                            />
                                                            Check that your
                                                            debit and credit
                                                            totals are equal
                                                        </FormHelperText>
                                                    </Box>
                                                </Grid>
                                            )}

                                            {/* Add Line Button */}
                                            <Grid item xs={12}>
                                                <Button
                                                    fullWidth
                                                    variant="outlined"
                                                    onClick={() =>
                                                        push({
                                                            account: null,
                                                            debit: '',
                                                            credit: '',
                                                        })
                                                    }
                                                    sx={{ mt: 2 }}
                                                >
                                                    Add a line
                                                </Button>
                                            </Grid>
                                        </>
                                    )}
                                </FieldArray>
                            </Grid>
                        </Box>

                        {/* Form Actions */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: 3,
                            }}
                        >
                            <Button
                                sx={classes.cancelButton}
                                onClick={handleCloseModal}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                loading={isLoading}
                            >
                                {type === 'Add' ? 'Create' : 'Update'}
                            </LoadingButton>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default JournalForm;
